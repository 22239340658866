// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: we want maintain "noImplicitAny": true, for strong typing
import arbManifest from '@translations/arbManifest';
import React, { useEffect, FC } from 'react';
import { useLocalizationContext } from '@amzn/react-arb-tools';
import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { useSelector } from 'react-redux';
import { selectLocale } from './state/redux';

// Define a localization context builder
const localizationContextBuilder =
  new LocalizationContextBuilder().withDefaultLocale('en-US');

// replace with your dev desktop name
// const devDesktopOverride = undefined;
const devDesktopOverride =
  'https://dev-dsk-lmk-1e-0e699a5a.us-east-1.amazon.com:4321/translations';

export type IntlContext = {
  getMessage: (id: string) => string;
  formatMessage: (id: string, ...args: any) => string;
};

export const mbmOption = {
  arbManifest,
  defaultLocalizationContext: localizationContextBuilder.build(),
  resolveBundleUrl: (url?: string) => {
    if (url) {
      return process.env.NODE_ENV === 'development'
        ? devDesktopOverride
          ? `${devDesktopOverride}/${url}`
          : `/translations/${url}`
        : `https://prod-eu-west-1.arms-registration.pace.amazon.dev/translations/${url}`;
    }
    return undefined;
  },
};

const LocaleProvider: FC<React.PropsWithChildren<NonNullable<unknown>>> = (
  props: React.PropsWithChildren<NonNullable<unknown>>,
) => {
  const locale = useSelector(selectLocale);
  const { setLocalizationContext } = useLocalizationContext();

  useEffect(() => {
    if (locale != null) {
      const localizationContextBuilder =
        new LocalizationContextBuilder().withDefaultLocale('en-US');
      const localeFormatted = locale.replace('_', '-');
      setLocalizationContext(
        localizationContextBuilder.withLocale(localeFormatted).build(),
      );
    }
  }, [locale]);

  // the App should not be rendered unless we know the customers LoP
  // This avoids flickering with different translation
  if (locale == null) {
    return null;
  }

  return <>{props.children}</>;
};

export default LocaleProvider;
